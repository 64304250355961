var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"items":_vm.items,"headers":_vm.headers,"server-items-length":_vm.total,"options":_vm.options,"loading":_vm.loading,"expanded":_vm.expanded,"fixed-header":"","single-expand":"","show-expand":"","height":_vm.tableHeight},on:{"update:options":function($event){_vm.options=$event},"update:expanded":function($event){_vm.expanded=$event},"pagination":_vm.updatePage,"item-expanded":_vm.fetchClaimDetail},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_c('span',[_c('v-simple-table',{staticClass:"mt-5 mb-5",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[(!item.approved && item.rejectedDate)?_c('tr',[_c('th',{staticClass:"text-right",attrs:{"width":"30%"}},[_vm._v(" Rejected Date: ")]),_c('td',{attrs:{"width":"70%"}},[_vm._v(" "+_vm._s(item.rejectedDate)+" ")])]):_vm._e(),(!item.approved && item.rejectedReason)?_c('tr',[_c('th',{staticClass:"text-right",attrs:{"width":"30%"}},[_vm._v(" Rejected Reason: ")]),_c('td',{attrs:{"width":"70%"}},[_vm._v(" "+_vm._s(item.rejectedReason)+" ")])]):_vm._e(),_vm._l((_vm.expandedClaimFieldValues),function(claimFieldValue){return _c('tr',{key:claimFieldValue.id},[_c('th',{staticClass:"text-right",attrs:{"width":"30%"}},[_vm._v(" "+_vm._s(claimFieldValue.claimField.name)+" ")]),_c('td',{attrs:{"width":"70%"}},[_vm._v(" "+_vm._s(claimFieldValue.valueFormatted)+" ")])])}),_vm._l((_vm.expandedClaimUploads),function(claimUpload,i){return _c('tr',{key:i + '-uploads'},[_c('th',{staticClass:"text-right",attrs:{"width":"30%"}},[_vm._v("File "+_vm._s(i + 1))]),_c('td',{attrs:{"width":"70%"}},[_c('a',{attrs:{"href":claimUpload.href,"target":"_blank","rel":"noreferrer"}},[_vm._v(_vm._s(claimUpload.originalFilename))]),_vm._v(" ("+_vm._s(claimUpload.contentType)+") ")])])})],2)]},proxy:true}],null,true)}),_c('v-data-table',{staticClass:"mt-5 mb-5",attrs:{"dense":"","calculate-widths":true,"hide-default-footer":"","headers":_vm.productHeaders,"items":_vm.expandedClaimProducts[item.id]},scopedSlots:_vm._u([{key:"item.calculatedTotalPayoutAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toNumber")(item.calculatedTotalPayoutAmount,2, _vm.selectedProgram))+" "+_vm._s(item.calculatedTotalPayoutAmountCurrency ? item.calculatedTotalPayoutAmountCurrency : "")+" ")]}}],null,true)})],1)],1)]}},{key:"item.claimKey",fn:function(ref){
var item = ref.item;
return [(!item.submitted)?_c('v-btn',{attrs:{"to":{ name: 'cprClaim', params: { id: item.promotion.id }, query: { claimId: item.id } },"color":"primary"}},[_vm._v(" "+_vm._s(item.claimKey)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.claimKey))])]}},{key:"item.calculatedTotalClaimAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toNumber")(item.calculatedCalculatedTotalClaimAmount,2, _vm.selectedProgram))+" "+_vm._s(item.calculatedCalculatedTotalClaimAmountCurrency ? item.calculatedCalculatedTotalClaimAmountCurrency : "")+" ")]}},{key:"item.createdDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateClient")(item.updatedDate,"MM/DD/YYYY", _vm.selectedClient))+" ")]}},{key:"item.invoiceDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateClient")(item.invoiceDate,"MM/DD/YYYY", _vm.selectedClient))+" ")]}},{key:"item.claimStage.name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("formatStage")(item.claimStage,item))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.claimStage.description))])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }